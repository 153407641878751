import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { getDriverRecap } from "../api/driverRecap.api";
import { getFirstDateOfCurrentMonth } from "../utility/getFirstDateOfCurrentMonth";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";

dayjs.extend(timezone);
export default function DriverRecapOverview() {
  const [startDate, setStartDate] = useState<Date | null>(
    getFirstDateOfCurrentMonth()
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      minWidth: 100,
    },
    {
      field: "first_name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.first_name}{" "}
            {params.row.last_name ? params.row.last_name : ""}
          </>
        );
      },
    },
    {
      field: "total_shift_seconds",
      headerName: "Total Shift Time",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.total_shift_seconds == undefined ||
            params.row.total_shift_seconds == null ? (
              <PlaceholderCharacter></PlaceholderCharacter>
            ) : (
              <>
                {Math.floor(params.row.total_shift_seconds / 3600)}h{" "}
                {Math.floor((params.row.total_shift_seconds % 3600) / 60)}m{" "}
              </>
            )}
          </>
        );
      },
    },
    {
      field: "rides_completed",
      headerName: "Rides Completed",
      flex: 1,
      renderCell: (params) => {
        return params.row.rides_completed ? params.row.rides_completed : 0;
      },
    },
    {
      field: "total_passengers",
      headerName: "Total Passengers",
      flex: 1,
      renderCell: (params) => {
        return params.row.total_passengers ? params.row.total_passengers : 0;
      },
    },
    {
      field: "avg_rating",
      headerName: "Average Rating",
      flex: 1,
      renderCell: (params) => {
        return params.row.avg_rating ? (
          parseFloat(params.row.avg_rating).toFixed(2)
        ) : (
          <PlaceholderCharacter></PlaceholderCharacter>
        );
      },
    },
    {
      field: "rating_count",
      headerName: "Rating Count",
      flex: 1,
      renderCell: (params) => {
        return params.row.rating_count ? (
          params.row.rating_count
        ) : (
          <PlaceholderCharacter></PlaceholderCharacter>
        );
      },
    },
    {
      field: "rating_count_negative",
      headerName: "Negative Ratings",
      flex: 1,
      renderCell: (params) => {
        if (params.row.rating_count == null) {
          return <PlaceholderCharacter></PlaceholderCharacter>;
        }
        if (params.row.rating_count_negative != 0) {
          return (
            <div style={{ color: "red", fontWeight: "bold" }}>
              {params.row.rating_count_negative}
            </div>
          );
        }
        return <>{params.row.rating_count_negative}</>;
      },
    },
  ];
  const navigate = useNavigate();
  function dateFormatter(date: Date) {
    return dayjs(date).format("YYYY-MM-DD");
  }
  const {
    data: driverRecapData,
    status,
    isLoading,
    isRefetching,
    refetch: refetchDriverRecap,
  } = useQuery("getDriverRecap", () =>
    getDriverRecap({
      endDate: dateFormatter(endDate!),
      startDate: dateFormatter(startDate!),
    })
  );

  return (
    <div>
      <>
        <div className="header-container">
          <h2>Driver Recap</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            margin: "20px 0",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={dayjs(startDate) as any}
              slotProps={{ textField: { size: "small", sx: { width: 250 } } }}
              onChange={(e: Date | null) => {
                if (dayjs(e).isValid()) {
                  let x = dayjs(e).toDate();
                  setStartDate(x);
                }
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              slotProps={{ textField: { size: "small", sx: { width: 250 } } }}
              value={dayjs(endDate) as any}
              onChange={(e: Date | null) => {
                if (dayjs(e).isValid()) {
                  let x = dayjs(e).toDate();
                  setEndDate(x);
                }
              }}
            />
          </LocalizationProvider>
          <button
            className="button-primary"
            onClick={(e) => {
              e.preventDefault();
              refetchDriverRecap();
            }}
          >
            Submit
          </button>
        </div>
        <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
          <DataGrid
            rowHeight={40}
            headerHeight={45}
            rows={driverRecapData?.drivers || []}
            getRowId={(row) => row.id}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            loading={isLoading || isRefetching}
          />
        </div>
      </>
    </div>
  );
}

import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

export interface Promotion {
  id?: number;
  title?: string;
  thumb?: string;
  details?: string;
  merchant_id?: number;
  begin_date?: number;
  end_date?: number;
  creation_date?: number;
  update_date?: number;
  priority?: number;
  status?: number;
  merchant_name?: string;
}
interface PromotionResponse {
  success: boolean;
  promotions: Promotion[];
}

export const getPromotions = async function (): Promise<PromotionResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<PromotionResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/promotions`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getAPromotion = async function (
  id: number | undefined
): Promise<PromotionResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/promotions/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const updateAPromotion = async function (promotion: Promotion) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/promotions`,
    promotion,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const createAPromotion = async function (promotion: Promotion) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/promotions`,
    promotion,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

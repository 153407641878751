import InfoIcon from "@mui/icons-material/Info";
import { LinearProgress, MenuItem, TextField, Tooltip } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";
import { getVehicleTypes } from "../api/vehicle_types.api";
import { Vehicle, getAVehicle, updateAVehicle } from "../api/vehicles.api";
import BackButton from "./BackButton";
import StatusOptions from "./utility/StatusOptions";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import { Link } from "react-router-dom";

export default function VehicleSingle() {
  const { id } = useParams() as { id: string };
  const { data, status, isLoading, isRefetching } = useQuery(
    "getAVehicle",
    () => getAVehicle(parseInt(id)),
    { refetchOnMount: true }
  );
  const {
    data: vehicleTypeData,
    status: vehicleTypeStatus,
    isLoading: vehicleTypeIsLoading,
    isRefetching: vehicleTypeIsFetching,
  } = useQuery("getVehicleTypes", () => getVehicleTypes(), {
    refetchOnMount: true,
  });
  const { mutateAsync, isLoading: isMutationLoading } = useMutation(
    updateAVehicle,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getVehicles");
        queryClient.invalidateQueries("getAVehicle");
        enqueueSnackbar("Vehicle was succesfully edited.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem editing this vehicle. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  const [editEnabled, setEditEnabled] = useState(false);
  const [updatedVehicle, setUpdatedVehicle] = useState<Vehicle>({
    id: parseInt(id),
  });

  async function updateVehicle() {
    let payload = updatedVehicle;

    if (payload.rider_display_name == "") {
      payload.rider_display_name = null;
    }
    await mutateAsync(payload);
    setEditEnabled(false);
  }
  useEffect(() => {
    setUpdatedVehicle({ id: parseInt(id) });
  }, [editEnabled]);

  //set initial vehicle type
  useEffect(() => {
    if (data) {
      let vehicleTypeMatchingId = vehicleTypeData?.vehicle_types.filter(
        (vt) => {
          return vt.id == data?.vehicle[0].vehicle_type_id;
        }
      );
      if (vehicleTypeMatchingId) {
        setUpdatedVehicle({
          ...updatedVehicle,
          vehicle_type_id: vehicleTypeMatchingId[0].id,
        });
      }
    }
  }, [data, vehicleTypeData]);
  function handleSelectChange(e: React.ChangeEvent<any>) {
    setUpdatedVehicle({
      ...updatedVehicle,
      vehicle_type_id: e.target.value,
    });
  }
  function handleEnableSelect(e: React.ChangeEvent<any>) {
    setUpdatedVehicle({
      ...updatedVehicle,
      status: e.target.value,
    });
  }

  if (isLoading || isRefetching || isMutationLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <BackButton></BackButton>
      <h2>
        Vehicle Information{" "}
        <button
          onClick={(e) => {
            setEditEnabled(!editEnabled);
          }}
          className="button-primary-sm"
        >
          Edit
        </button>
      </h2>
      <div
        className={editEnabled ? "asset-container editmode" : "asset-container"}
      >
        <form onSubmit={updateVehicle}>
          <div className="field-block">
            <span className="label">
              Nickname{" "}
              <Tooltip
                placement="top"
                title="The name that will be displayed in the driver app."
                style={{ marginLeft: "10px" }}
              >
                <InfoIcon fontSize="small" color="disabled"></InfoIcon>
              </Tooltip>
            </span>

            {editEnabled ? (
              <TextField
                required
                inputProps={{ maxLength: 255 }}
                size="small"
                defaultValue={data?.vehicle[0].nickname}
                placeholder={data?.vehicle[0].nickname}
                onChange={(e) =>
                  setUpdatedVehicle({
                    ...updatedVehicle,
                    nickname: e.target.value,
                  })
                }
              ></TextField>
            ) : data?.vehicle[0].nickname ? (
              data?.vehicle[0].nickname
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </div>
          <div className="field-block">
            <span className="label">Rider Display Name</span>
            {editEnabled ? (
              <TextField
                inputProps={{ maxLength: 255 }}
                size="small"
                defaultValue={data?.vehicle[0].rider_display_name}
                placeholder={data?.vehicle[0].rider_display_name}
                onChange={(e) =>
                  setUpdatedVehicle({
                    ...updatedVehicle,
                    rider_display_name: e.target.value,
                  })
                }
              ></TextField>
            ) : data?.vehicle[0].rider_display_name ? (
              data?.vehicle[0].rider_display_name
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </div>
          <div className="field-block">
            <span className="label">VIN</span>
            {editEnabled ? (
              <TextField
                required
                inputProps={{ maxLength: 17 }}
                size="small"
                defaultValue={data?.vehicle[0].vin_number}
                placeholder={data?.vehicle[0].vin_number}
                onChange={(e) =>
                  setUpdatedVehicle({
                    ...updatedVehicle,
                    vin_number: e.target.value,
                  })
                }
              ></TextField>
            ) : (
              data?.vehicle[0].vin_number
            )}
          </div>

          <div className="field-block">
            <span className="label">Tag Number</span>
            {editEnabled ? (
              <TextField
                required
                inputProps={{ maxLength: 16 }}
                size="small"
                defaultValue={data?.vehicle[0].tag_number}
                placeholder={data?.vehicle[0].tag_number}
                onChange={(e) =>
                  setUpdatedVehicle({
                    ...updatedVehicle,
                    tag_number: e.target.value,
                  })
                }
              ></TextField>
            ) : data?.vehicle[0].tag_number ? (
              data?.vehicle[0].tag_number
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </div>

          <div className="field-block">
            <span className="label">Internal Number</span>
            {editEnabled ? (
              <TextField
                required
                type="number"
                size="small"
                defaultValue={data?.vehicle[0].internal_number}
                // placeholder={data?.vehicle[0].internal_number}
                onChange={(e) =>
                  setUpdatedVehicle({
                    ...updatedVehicle,
                    internal_number: parseInt(e.target.value),
                  })
                }
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
              ></TextField>
            ) : data?.vehicle[0].internal_number == 0 ||
              data?.vehicle[0].internal_number ? (
              data?.vehicle[0].internal_number
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </div>

          <div className="field-block">
            <span className="label">Status</span>
            {editEnabled ? (
              <TextField
                select
                size="small"
                defaultValue={data?.vehicle[0].status}
                onChange={handleEnableSelect}
              >
                <MenuItem value={0}>Enabled</MenuItem>
                <MenuItem value={1}>Disabled</MenuItem>
              </TextField>
            ) : (
              <>
                <StatusOptions
                  status={data?.vehicle[0].status!}
                ></StatusOptions>
              </>
            )}
          </div>
          <div className="field-block">
            <span className="label">Sort Priority</span>
            {editEnabled ? (
              <TextField
                size="small"
                type="number"
                defaultValue={data?.vehicle[0].sort_priority}
                onChange={(e) =>
                  setUpdatedVehicle({
                    ...updatedVehicle,
                    sort_priority: parseInt(e.target.value),
                  })
                }
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
              ></TextField>
            ) : (
              data?.vehicle[0].sort_priority
            )}
          </div>
          {!editEnabled && (
            <>
              <div className="field-block">
                <span className="label">Vehicle Type</span>
                <Link
                  to={`/dashboard/vehicle-types/${data?.vehicle[0].vehicle_type_id}`}
                >
                  {data?.vehicle[0].name} - {data?.vehicle[0].max_passengers}/
                  {data?.vehicle[0].max_passengers_hops}
                </Link>
              </div>
            </>
          )}

          {editEnabled && (
            <div>
              <h2 style={{ marginTop: "50px" }}>Change Vehicle Type</h2>
              <div className="field-block">
                <span className="label">Name</span>
                <TextField
                  sx={{ width: "300px" }}
                  select
                  value={
                    updatedVehicle.vehicle_type_id ||
                    data?.vehicle[0].vehicle_type_id
                  }
                  onChange={handleSelectChange}
                >
                  {vehicleTypeData?.vehicle_types.map((v: any) => {
                    return (
                      <MenuItem value={v.id}>
                        {v.name} - {v.max_passengers} / {v.max_passengers_hops}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            </div>
          )}
          {editEnabled && (
            <div className="button-container">
              <button
                className="button-outline btn"
                onClick={(e) => {
                  setEditEnabled(false);
                }}
              >
                Cancel
              </button>
              <button className="button-primary btn">Save</button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

export interface RouteStop {
  id?: number;
  route_id?: number;
  stop_id?: number;
  sequence?: number;
  description?: string | null;
  default_travel_time?: number;
  default_service_time?: number;
  optional?: number;
  status?: number;
  route_name?: string;
  stop_name?: string;
}
interface RouteStopResponse {
  success: boolean;
  routes_stops: RouteStop[];
}

export const getRouteStops = async function (): Promise<RouteStopResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<RouteStopResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/routes-stops`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getARouteStop = async function (
  id: number | undefined
): Promise<RouteStopResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/routes-stops/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const getARoutesRouteStops = async function (
  id: number | undefined
): Promise<RouteStopResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/routes-stops/route/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const updateRouteStopSequence = async function (
  routestops: RouteStop[]
) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/routes-stops/sequence`,
    { updateStops: routestops },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const updateARouteStop = async function (routestop: RouteStop) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/routes-stops`,
    routestop,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const createARouteStop = async function (routestop: RouteStop) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/routes-stops`,
    routestop,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

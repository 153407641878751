import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";

export interface Stop {
  id?: number;
  code?: string;
  name?: string;
  description?: string;
  longitude?: number;
  latitude?: number;
}
interface StopResponse {
  success: boolean;
  stops: Stop[];
}

interface ConnectedRoute {
  route_id?: number;
  route_name?: string;
  stop_name?: string;
  route_stop_id?: number;
  description?: string | null;
  sequence?: number;
  optional?: number;
  status?: number;
}
interface ConnectedRoutesResponse {
  success: boolean;
  connectedRoutes: ConnectedRoute[];
}

export const getStops = async function (): Promise<StopResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<StopResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/stops`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getAStop = async function (
  id: number | undefined
): Promise<StopResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/stops/${id}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const updateAStop = async function (stop: Stop) {
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/stops`,
    stop,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const createAStop = async function (stop: Stop) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/stops`,
    stop,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getConnectedRoutes = async function (
  id: number | undefined
): Promise<ConnectedRoutesResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/stops/${id}/connected-routes`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

import axios from "axios";
import { useAuthStore } from "../stores/auth.store.zus";
export interface Route {
  id?: number;
  name?: string;
  description?: string;
  redirect_description?: string;
  polygon?: string;
  color?: string;
  text_color?: string;
  prediction_depth?: number;
  type?: number;
  auto_hide?: number;
  priority?: number;
  status?: number;
}
interface RoutesResponse {
  success: boolean;
  routes: Route[];
}

export const getRoutes = async function (): Promise<RoutesResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<RoutesResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/routes`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
};

export const getFixedRoutes = async function (): Promise<RoutesResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<RoutesResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/routes/fixed`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const getDeviatedRoutes = async function (): Promise<RoutesResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<RoutesResponse>(
    `${process.env.REACT_APP_API_BASE_URL}/routes/deviated`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getARoute = async function (
  jobId: number | undefined
): Promise<RoutesResponse> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/routes/${jobId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const updateARoute = async function (route: Route) {
  console.log(route);
  const token = useAuthStore.getState().token;
  const res = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/routes`,
    route,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const createARoute = async function (route: Route) {
  const token = useAuthStore.getState().token;
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/routes`,
    route,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
export const getRouteKml = async function (routeId: number) {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/kml/routes/${routeId}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
  return res.data;
};

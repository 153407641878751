import { LinearProgress, MenuItem, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";
import {
  SystemParams,
  getASystemParam,
  updateASystemParam,
} from "../api/sys_params.api";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import SystemParameterTypes from "./utility/SystemParameterTypes";
import SystemParameterUnits from "./utility/SystemParameterUnits";

export default function SystemParametersSingleInformation() {
  const { id } = useParams() as { id: string };

  const { data, status, isLoading, refetch, isRefetching } = useQuery(
    ["getASystemParam", parseInt(id)],
    () => getASystemParam(parseInt(id)),
    { refetchOnMount: true }
  );
  const { mutateAsync, isLoading: isMutationLoading } = useMutation(
    updateASystemParam,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getASystemParam", parseInt(id)]);
        queryClient.invalidateQueries("getSystemParams");
        enqueueSnackbar("System Parameter was succesfully edited.", {
          variant: "success",
        });
        setUpdatedSystemParameter({ id: parseInt(id) });
      },
      onError: async (error: unknown) => {
        setUpdatedSystemParameter({ id: parseInt(id) });
        enqueueSnackbar(
          `There was a problem editing this System Parameter. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  const [editEnabled, setEditEnabled] = useState(false);

  const [updatedSystemParameter, setUpdatedSystemParameter] =
    useState<SystemParams>({
      id: parseInt(id),
    });
  async function updateSystemParameterHandler(
    e: React.FormEvent<HTMLFormElement>
  ) {
    e.preventDefault();
    await mutateAsync(updatedSystemParameter);
    setEditEnabled(false);
  }
  useEffect(() => {
    setUpdatedSystemParameter({ id: parseInt(id) });
  }, [editEnabled]);

  if (isLoading || isRefetching || isMutationLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <h2>
        System Parameter Information{" "}
        <button
          onClick={(e) => {
            setEditEnabled(!editEnabled);
          }}
          className="button-primary-sm"
        >
          Edit
        </button>
      </h2>
      <div
        className={editEnabled ? "asset-container editmode" : "asset-container"}
      >
        <form onSubmit={updateSystemParameterHandler}>
          <div className="field-block">
            <span className="label">ID</span>
            {editEnabled ? data?.sys_params[0].id : data?.sys_params[0].id}
          </div>
          <div className="field-block">
            <span className="label">Name</span>
            {editEnabled ? data?.sys_params[0].name : data?.sys_params[0].name}
          </div>
          <div className="field-block">
            <span className="label">Type</span>
            {editEnabled ? (
              <SystemParameterTypes
                type={data?.sys_params[0].type!}
              ></SystemParameterTypes>
            ) : (
              <SystemParameterTypes
                type={data?.sys_params[0].type!}
              ></SystemParameterTypes>
            )}
          </div>

          <div className="field-block">
            <span className="label">Unit</span>
            {editEnabled ? (
              <SystemParameterUnits
                unit={data?.sys_params[0].unit!}
              ></SystemParameterUnits>
            ) : (
              <SystemParameterUnits
                unit={data?.sys_params[0].unit!}
              ></SystemParameterUnits>
            )}
          </div>
          <div className="field-block">
            <span className="label">Description</span>
            {editEnabled
              ? data?.sys_params[0].description
              : data?.sys_params[0].description}
          </div>
          <div className="field-block">
            <span className="label">Value</span>
            {editEnabled ? (
              <>
                {(data?.sys_params[0].type == 1 ||
                  data?.sys_params[0].type == 2 ||
                  data?.sys_params[0].type == 3 ||
                  data?.sys_params[0].type == 5 ||
                  data?.sys_params[0].type == 6 ||
                  data?.sys_params[0].type == 8 ||
                  data?.sys_params[0].type == 9) && (
                  <TextField
                    type="number"
                    size="small"
                    style={{ width: "500px" }}
                    inputProps={{ step: "any" }}
                    required
                    defaultValue={data?.sys_params[0].value}
                    sx={{
                      flex: 1.5,
                      "& input[type=number]": {
                        "-moz-appearance": "textfield",
                        "&::-webkit-outer-spin-button": {
                          "-webkit-appearance": "none",
                          margin: 0,
                        },
                        "&::-webkit-inner-spin-button": {
                          "-webkit-appearance": "none",
                          margin: 0,
                        },
                      },
                    }}
                    onChange={(e) =>
                      setUpdatedSystemParameter({
                        ...updatedSystemParameter,
                        value: e.target.value,
                      })
                    }
                    onWheel={(e) =>
                      e.target instanceof HTMLElement && e.target.blur()
                    }
                  ></TextField>
                )}
                {(data?.sys_params[0].type == 0 ||
                  data?.sys_params[0].type == 7) && (
                  <TextField
                    type="text"
                    size="small"
                    style={{ width: "500px" }}
                    required
                    defaultValue={data?.sys_params[0].value}
                    inputProps={{
                      maxLength: 255,
                    }}
                    onChange={(e) =>
                      setUpdatedSystemParameter({
                        ...updatedSystemParameter,
                        value: e.target.value,
                      })
                    }
                  ></TextField>
                )}
                {data?.sys_params[0].type == 4 && (
                  <TextField
                    select
                    size="small"
                    style={{ width: "500px" }}
                    defaultValue={data?.sys_params[0].value == "false" ? 0 : 1}
                    onChange={(e) => {
                      if (parseInt(e.target.value) == 0) {
                        setUpdatedSystemParameter({
                          ...updatedSystemParameter,
                          value: "false",
                        });
                      }
                      if (parseInt(e.target.value) == 1) {
                        setUpdatedSystemParameter({
                          ...updatedSystemParameter,
                          value: "true",
                        });
                      }
                    }}
                  >
                    <MenuItem value={0}>false</MenuItem>
                    <MenuItem value={1}>true</MenuItem>
                  </TextField>
                )}
              </>
            ) : (
              data?.sys_params[0].value
            )}
          </div>
          <div className="field-block">
            <span className="label">Update Date</span>
            {editEnabled ? (
              <>
                {data?.sys_params[0].update_date! > 0 && (
                  <>
                    {new Date(
                      data?.sys_params[0].update_date! * 1000
                    ).toLocaleString()}
                  </>
                )}
                {data?.sys_params[0].update_date! == 0 && (
                  <PlaceholderCharacter></PlaceholderCharacter>
                )}
              </>
            ) : (
              <>
                {data?.sys_params[0].update_date! > 0 && (
                  <>
                    {new Date(
                      data?.sys_params[0].update_date! * 1000
                    ).toLocaleString()}
                  </>
                )}
                {data?.sys_params[0].update_date! == 0 && (
                  <PlaceholderCharacter></PlaceholderCharacter>
                )}
              </>
            )}
          </div>
          {editEnabled && (
            <div className="button-container">
              <button
                className="button-outline btn"
                onClick={(e) => {
                  setEditEnabled(false);
                }}
              >
                Cancel
              </button>
              <button
                className="button-primary btn"
                type="submit"
                disabled={Object.keys(updatedSystemParameter).length <= 1}
              >
                Save
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

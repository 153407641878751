import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { getADriversRatings, getADriversRatingsKpi } from "../api/drivers.api";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import StatsBlock from "./widgets/StatBlock";
import { useSysParamsStore } from "../stores/sysParams.store.zus";
import dayjs from "dayjs";

export default function DriversSingleRatings() {
  const columns: GridColDef[] = [
    {
      field: "ride_id",
      headerName: "Ride Id",
      flex: 1,
    },
    {
      field: "pick_up_date",
      headerName: "Pick Up Date",
      flex: 1,
      renderCell: (params) => {
        return dayjs(params.row.pick_up_date * 1000)
          .tz(
            useSysParamsStore.getState().getParamByName("global_timezone")
              ?.value
          )
          .format("MMM D, YYYY");
      },
    },
    {
      field: "rating",
      headerName: "Rating",
      flex: 1,
    },
    {
      field: "feedback_comment",
      headerName: "Feedback Comment",
      flex: 1,

      renderCell: (params) => {
        return params.row.feedback_comment ? (
          params.row.feedback_comment
        ) : (
          <PlaceholderCharacter></PlaceholderCharacter>
        );
      },
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                navigate(`/dashboard/rides/${params.row.ride_id}`);
              }}
              className="button-primary-sm"
            >
              View
            </button>
          </>
        );
      },
    },
  ];
  const { id } = useParams() as { id: string };

  const navigate = useNavigate();

  const { data, status, isLoading, isRefetching } = useQuery(
    "getADriversRatings",
    () => getADriversRatings(parseInt(id))
  );
  const {
    data: ratingsKpi,
    isLoading: ratingsKpiIsLoading,
    isRefetching: ratingsKpiIsRefetching,
  } = useQuery("getADriversRatingsKpi", () =>
    getADriversRatingsKpi(parseInt(id))
  );

  if (
    isLoading ||
    ratingsKpiIsLoading ||
    ratingsKpiIsRefetching ||
    isRefetching
  ) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      {data && ratingsKpi && (
        <>
          <div className="header-container">
            <h2>Ratings</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              margin: "20px 0",
            }}
          >
            <StatsBlock
              label={"Average Rating"}
              value={
                ratingsKpi?.ratingsKpi[0].avg_rating
                  ? ratingsKpi?.ratingsKpi[0].avg_rating.toFixed(2)
                  : null
              }
            ></StatsBlock>
            <StatsBlock
              label={"Average Rating 30 Days"}
              value={
                ratingsKpi?.ratingsKpi[0].avg_rating_30_days
                  ? ratingsKpi?.ratingsKpi[0].avg_rating_30_days.toFixed(2)
                  : null
              }
            ></StatsBlock>
            <StatsBlock
              label={"Rating Count"}
              value={ratingsKpi?.ratingsKpi[0].rating_count}
            ></StatsBlock>
            <StatsBlock
              label={"Rating Count 30 Days"}
              value={ratingsKpi?.ratingsKpi[0].rating_count_30_days}
            ></StatsBlock>
          </div>
          <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
            <DataGrid
              rowHeight={40}
              headerHeight={45}
              rows={data?.ratings || []}
              getRowId={(row) => row.ride_id}
              columns={columns}
              pageSize={15}
              rowsPerPageOptions={[15]}
            />
          </div>
        </>
      )}
    </div>
  );
}

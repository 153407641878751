import { LinearProgress, MenuItem, TextField, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";
import { Driver, getADriver, updateADriver } from "../api/drivers.api";
import { useSysParamsStore } from "../stores/sysParams.store.zus";
import ImagePreview from "./ImagePreview";
import DriverModeOptions from "./utility/DriverModeOptions";
import ImageUploaderCrop from "./utility/ImageUploaderCrop";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import UserStatusOptions from "./utility/UserStatusOptions";

dayjs.extend(advancedFormat);

export default function DriversSingleInformation() {
  const { id } = useParams() as { id: string };
  const { data, status, isLoading, isRefetching } = useQuery("getADriver", () =>
    getADriver(parseInt(id))
  );
  const [editEnabled, setEditEnabled] = useState(false);
  const [updatedDriver, setUpdatedDriver] = useState<Driver>({
    id: parseInt(id),
  });
  const { mutateAsync, isLoading: isMutationLoading } = useMutation(
    updateADriver,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getADriver");
        enqueueSnackbar("Driver was succesfully edited.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem editing this driver. Error: ${
            (error as any).response.data.error.message || error
          }`,
          {
            variant: "error",
          }
        );
      },
    }
  );

  async function updateDriver(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let payload = { ...updatedDriver };
    if (payload.external_employee_identifier?.trim() === "") {
      payload.external_employee_identifier = null;
    }
    await mutateAsync(payload);
    setEditEnabled(false);
  }
  useEffect(() => {
    setUpdatedDriver({ id: parseInt(id) });
  }, [editEnabled]);
  function thumbIconHandler(url: string) {
    setUpdatedDriver({
      ...updatedDriver,
      thumb: url,
    });
  }
  function renderDeleteFields() {
    if (editEnabled && updatedDriver.status! == 0) {
      return false;
    }
    if (data?.drivers[0].status! > 0 || updatedDriver.status! > 0) {
      return true;
    }
    return false;
  }
  function emailRegexCheck() {
    if (updatedDriver.email == "") {
      return true;
    }
    if (updatedDriver.email) {
      let v = !/^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
        updatedDriver.email
      );
      return v;
    }
    if (data?.drivers[0].email) {
      return false;
    }
  }
  function phoneRegexCheck() {
    if (updatedDriver.phone_number) {
      let v = !/^[\d+ -]{5,32}$/.test(updatedDriver.phone_number);
      return v;
    }
    if (data?.drivers[0].phone_number) {
      return false;
    }
  }

  if (isLoading || isRefetching || isMutationLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  if (data && data.drivers[0]) {
    return (
      <div>
        <h2>
          Driver Information{" "}
          <button
            onClick={(e) => {
              setEditEnabled(!editEnabled);
            }}
            className="button-primary-sm"
          >
            Edit
          </button>
        </h2>
        <div
          className={
            editEnabled ? "asset-container editmode" : "asset-container"
          }
        >
          <form onSubmit={updateDriver}>
            <div className="field-block">
              <span className="label">ID</span>
              {editEnabled ? <>{data?.drivers[0].id}</> : data?.drivers[0].id}
            </div>

            <div className="field-block">
              <span className="label">Email</span>
              {editEnabled ? (
                <TextField
                  sx={{ width: 300 }}
                  size="small"
                  label="Email"
                  error={emailRegexCheck()}
                  defaultValue={data?.drivers[0].email}
                  placeholder={data?.drivers[0].email}
                  value={updatedDriver.email}
                  helperText={
                    emailRegexCheck()
                      ? "Please enter a properly formatted email address."
                      : ""
                  }
                  onChange={(e) => {
                    setUpdatedDriver({
                      ...updatedDriver,
                      email: e.target.value,
                    });
                  }}
                ></TextField>
              ) : (
                data?.drivers[0].email
              )}
            </div>
            <div className="field-block">
              <span className="label">First Name</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  sx={{ width: 300 }}
                  inputProps={{ minLength: 2, maxLength: 64 }}
                  defaultValue={data?.drivers[0].first_name}
                  placeholder={data?.drivers[0].first_name || ""}
                  onChange={(e) =>
                    setUpdatedDriver({
                      ...updatedDriver,
                      first_name: e.target.value,
                    })
                  }
                ></TextField>
              ) : (
                data?.drivers[0].first_name
              )}
            </div>
            <div className="field-block">
              <span className="label">Last Name</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  sx={{ width: 300 }}
                  inputProps={{ minLength: 2, maxLength: 64 }}
                  defaultValue={data?.drivers[0].last_name}
                  placeholder={data?.drivers[0].last_name || ""}
                  onChange={(e) =>
                    setUpdatedDriver({
                      ...updatedDriver,
                      last_name: e.target.value,
                    })
                  }
                ></TextField>
              ) : (
                data?.drivers[0].last_name
              )}
            </div>

            <div className="field-block">
              <span className="label">Phone Number</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ maxLength: 32 }}
                  size="small"
                  sx={{ width: 300 }}
                  defaultValue={data?.drivers[0].phone_number}
                  placeholder={data?.drivers[0].phone_number}
                  value={updatedDriver.phone_number}
                  error={phoneRegexCheck()}
                  helperText={
                    phoneRegexCheck()
                      ? "Please enter a properly formatted phone number."
                      : ""
                  }
                  onChange={(e) =>
                    setUpdatedDriver({
                      ...updatedDriver,
                      phone_number: e.target.value,
                    })
                  }
                ></TextField>
              ) : (
                data?.drivers[0].phone_number
              )}
            </div>
            <div className="field-block">
              <span className="label">Creation Date</span>
              {data?.drivers[0].creation_date ? (
                dayjs(new Date(data?.drivers[0].creation_date * 1000))
                  .tz(
                    useSysParamsStore
                      .getState()
                      .getParamByName("global_timezone")?.value
                  )
                  .format("MM/DD/YYYY hh:mm A z")
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            <div className="field-block">
              <span className="label">Update Date</span>
              {data?.drivers[0].update_date ? (
                dayjs(new Date(data?.drivers[0].update_date * 1000))
                  .tz(
                    useSysParamsStore
                      .getState()
                      .getParamByName("global_timezone")?.value
                  )
                  .format("MM/DD/YYYY hh:mm A z")
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>

            <div className="field-block">
              <span className="label">Status</span>
              {editEnabled ? (
                <TextField
                  select
                  size="small"
                  sx={{ width: 300 }}
                  defaultValue={data?.drivers[0].status}
                  disabled
                  onChange={(e) =>
                    setUpdatedDriver({
                      ...updatedDriver,
                      status: parseInt(e.target.value),
                    })
                  }
                >
                  <MenuItem value={0}>Enabled</MenuItem>
                  <MenuItem value={1}>Disabled</MenuItem>
                  <MenuItem value={2}>Blocked</MenuItem>
                </TextField>
              ) : (
                <UserStatusOptions
                  status={data?.drivers[0].status!}
                ></UserStatusOptions>
              )}
            </div>
            <div className="field-block">
              <span className="label">Mode</span>
              {data?.drivers[0].mode ? (
                <DriverModeOptions
                  mode={data?.drivers[0].mode}
                ></DriverModeOptions>
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            <div className="field-block">
              <span className="label">Employee ID</span>
              {editEnabled ? (
                <Tooltip
                  placement="right"
                  title="If applicable, enter the Employee ID used in your payroll or HR system. This helps match drivers across platforms in reports."
                >
                  <TextField
                    size="small"
                    sx={{ width: 300 }}
                    inputProps={{ minLength: 1, maxLength: 64 }}
                    defaultValue={data?.drivers[0].external_employee_identifier}
                    placeholder={
                      data?.drivers[0].external_employee_identifier ||
                      "Optional"
                    }
                    onChange={(e) =>
                      setUpdatedDriver({
                        ...updatedDriver,
                        external_employee_identifier: e.target.value,
                      })
                    }
                  ></TextField>
                </Tooltip>
              ) : data?.drivers[0].external_employee_identifier ? (
                data?.drivers[0].external_employee_identifier
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
            <div className="field-block">
              <span className="label">Thumbnail</span>
              {editEnabled ? (
                <ImageUploaderCrop
                  labelText="Upload a thumbnail"
                  queryKey={"driverCreateThumbnailUrl"}
                  success={thumbIconHandler}
                ></ImageUploaderCrop>
              ) : (
                <ImagePreview
                  imageUrl={data?.drivers[0].thumb!}
                  showPlaceHolderDashes={true}
                ></ImagePreview>
              )}
            </div>

            {renderDeleteFields() && (
              <div className="field-block">
                <span className="label">Delete Date</span>
                {data?.drivers[0].delete_date ? (
                  dayjs(new Date(data?.drivers[0].delete_date * 1000))
                    .tz(
                      useSysParamsStore
                        .getState()
                        .getParamByName("global_timezone")?.value
                    )
                    .format("MM/DD/YYYY hh:mm A z")
                ) : (
                  <PlaceholderCharacter></PlaceholderCharacter>
                )}
              </div>
            )}
            {renderDeleteFields() && (
              <div className="field-block">
                <span className="label">Delete Reason</span>
                {editEnabled ? (
                  <TextField
                    size="small"
                    sx={{ width: 300 }}
                    inputProps={{ maxLength: 255 }}
                    defaultValue={data?.drivers[0].delete_reason}
                    placeholder={data?.drivers[0].delete_reason || ""}
                    onChange={(e) =>
                      setUpdatedDriver({
                        ...updatedDriver,
                        delete_reason: e.target.value,
                      })
                    }
                  ></TextField>
                ) : data?.drivers[0].delete_reason ? (
                  data?.drivers[0].delete_reason
                ) : (
                  <PlaceholderCharacter></PlaceholderCharacter>
                )}
              </div>
            )}

            {editEnabled && (
              <div className="button-container">
                <button
                  className="button-outline btn"
                  onClick={(e) => {
                    setEditEnabled(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="button-primary btn"
                  disabled={
                    emailRegexCheck() || phoneRegexCheck() || isMutationLoading
                  }
                >
                  Save
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }

  return <></>;
}
